import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyB_oE3e9zSusNnU6kFt5-4umHCubhNubwM",
  authDomain: "shotsnapp-4203e.firebaseapp.com",
  databaseURL: "https://shotsnapp-4203e.firebaseio.com",
  projectId: "shotsnapp-4203e",
  storageBucket: "shotsnapp-4203e.appspot.com",
  messagingSenderId: "199719426708"
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}
const db = firebase.firestore();
const auth = firebase.auth();

export { db, auth };
