import React from "react";
import AuthUserContext from "./AuthUserContext";
import { db } from "../firebase/firebase";
import { firebase } from "../firebase";
import { storeItem } from "../utilities/projectStorage";

const withAuthentication = Component =>
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        authUser: null,
        isLoading: true,
        getUserData: this.getUserData
      };
    }

    componentDidMount() {
      firebase.auth.onAuthStateChanged(authUser => {
        if (authUser !== null) {
          this.getUserData(authUser.uid);
          storeItem("isLoggedIn", true);
        } else {
          storeItem("isLoggedIn", false);
          this.setState({
            authUser: null,
            isLoading: false
          });
        }
      });
    }

    getUserData = uid => {
      // console.log("Getting user data...");
      // console.log(uid);
      var user = db.collection("users").doc(uid);
      user
        .get()
        .then(doc => {
          if (doc.exists) {
            this.setState({
              authUser: doc.data(),
              isLoading: false
            });
          } else {
            // console.log("No such document!");
            this.setState({
              isLoading: false
            });
          }
        })
        .catch(error => {
          console.log("Error getting document:", error);
        });
    };

    render() {
      return (
        <AuthUserContext.Provider value={this.state}>
          <Component />
        </AuthUserContext.Provider>
      );
    }
  };

export default withAuthentication;
