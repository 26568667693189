import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import withAuthentication from "./components/withAuthentication";
import "App.css";
import AuthUserContext from "components/AuthUserContext";
import Loadable from "react-loadable";
import Loader from "components/Loading";

const Loading = () => (
  <div className="fullLoading">
    <Loader style={{ width: 24, height: 24 }} size={24} />
    <p>
      Preparing the editor. <br />
      Please wait...
    </p>
  </div>
);

const Editor = Loadable({
  loader: () => import("./modules/Editor"),
  loading: Loading
});

const Clear = Loadable({
  loader: () => import("./modules/Editor/Clear"),
  loading: Loading
});

const NotFound = Loadable({
  loader: () => import("./modules/NotFound"),
  loading: Loading
});

const Upgrade = Loadable({
  loader: () => import("./modules/User/Signup"),
  loading: Loading
});

const Login = Loadable({
  loader: () => import("./modules/User/Login"),
  loading: Loading
});

const Account = Loadable({
  loader: () => import("./modules/User/Account"),
  loading: Loading
});

const Processing = Loadable({
  loader: () => import("./modules/User/Processing"),
  loading: Loading
});

class Root extends Component {
  render() {
    return (
      <AuthUserContext.Consumer>
        {({ authUser, isLoading, getUserData }) => (
          <div>
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Editor authUser={authUser} />}
              />
              <Route
                path="/t/:id"
                render={() => <Editor authUser={authUser} />}
              />

              <Route
                path="/clear"
                render={() => <Clear isLoading={isLoading} />}
              />
              <Route
                path="/upgrade"
                render={() => (
                  <Upgrade authUser={authUser} isLoading={isLoading} />
                )}
              />
              <Route
                path="/login"
                render={() => <Login authUser={authUser} />}
              />
              <Route
                path="/processing"
                render={() => (
                  <Processing
                    authUser={authUser}
                    isLoading={isLoading}
                    getUserData={getUserData}
                  />
                )}
              />
              <Route
                path="/account"
                render={() => (
                  <Account
                    authUser={authUser}
                    isLoading={isLoading}
                    getUserData={getUserData}
                  />
                )}
              />
              <Route component={NotFound} />
            </Switch>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withAuthentication(Root);
