import React from "react";
import styled, { keyframes } from "styled-components";
import FeatherIcon from "feather-icons-react";

const Loading = (props) => (
	<View style={props.style}>
		<FeatherIcon
			icon="loader"
			size={props.size || 18}
			color={props.color || "#ccc"}
		/>
	</View>
);
export default Loading;

const spin = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const View = styled.span`
	width: 20px;
	height: 20px;
	display: flex;
	justify-content: center;
	align-items: center;
	animation: ${spin} 2s linear infinite;
`;
